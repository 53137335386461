body {
  font-size: 16px;
  line-height: 24px;
  background-color: $very-light-gray;
}

ul {
  padding-left: 1em;
  > li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

input[type='checkbox'] {
  background-color: white;
  border: 2px solid $light-gray;

  &:hover {
    border-color: $grpm-gray;
  }

  &:checked {
    background-color: white;
    border-color: $grpm-gray;
  }

  &:checked:before {
    top: 1px;
    left: 6px;
    border-color: $dark-gray;
  }
}

.message {
  border-radius: 4px;

  &.warning:before {
    font-size: 18px;
  }
}

article.event {
  .subtitle {
    color: $dark-gray;
    font-weight: bold;
  }

  &.event-listing-item {
    h2 {
      margin-bottom: 0;
    }
    .subtitle {
      margin: 8px 0 16px;
    }
  }
}

.content-detail-page article.event {
  .subtitle {
    padding-bottom: 32px;
    border-bottom: $divider;
    margin-bottom: 32px;
  }

  .description p {
    line-height: 24px;
  }
}

.stepper {
  > button {
    border-radius: 4px;
  }

  > .count {
    background-color: white;
    font-weight: bold;
  }

  &.active > .count {
    background-color: $light-gray;
  }
}

.cart {
  border-radius: 4px;
  overflow: hidden;
  border: $divider;
  background-color: white;

  h2 {
    background-color: $blue;
    color: white;
  }

  .content {
    .ticket-wrapper {
      .ticket {
        .ticket-date,
        h4 {
          font-family: var(--primary-font);
          font-weight: bold;
        }
        .ticket-date {
          font-size: 14px;
          color: $medium-gray;
        }
      }
      .actions button.remove {
        color: $blue;
      }
    }

    .add-promo-form {
      padding: 16px;
    }

    .cart .content .apply-promo-codes .promo-number-field {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .add-promo-button {
      padding: 20px;
      color: $blue;
      font-weight: bold;
    }
  }

  button.primary.purchase {
    font-size: 20px;
    border-radius: 0;
  }

  .line-item .content-wrapper {
    @extend %p;
  }
}

.modal {
  .cart {
    border: 0;

    .content .ticket {
      padding-bottom: 0;
    }
  }
}

.complete {
  .main {
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 32px;
    background-color: $very-light-gray;

    h1 {
      font-size: 24px;
      font-weight: 500;

      span {
        color: inherit;
        font-size: 40px;
      }
    }

    code.order-number {
      color: $dark-gray;
    }
  }
}

.actions a {
  margin-top: 8px;
  margin-bottom: 8px;
}

.modal {
  overflow: hidden;

  &.login {
    h2 {
      margin-top: 0;
    }
  }
}

.modal {
  &.membership-modal {
    @include smallAndMedium {
      header {
        width: 100%;
        height: 192px;
        background-image: url(images/membership-modal-mobile.jpg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    @include mobileExperience {
      header {
        height: auto;
        background-image: none;
      }
    }

    .modal-body {
      @include large {
        display: flex;
        @include membershipModalImage(auto) {
          background-image: url(images/membership-modal.jpg);
          flex-basis: 336px;
        }
      }

      .membership-modal-inner {
        width: auto;
        flex-shrink: 1;

        @include large {
          padding-left: 16px;
        }
      }
    }
  }
}

form.login {
  > .actions {
    display: block;
  }

  > .become-member-link {
    margin-top: 24px;
    border-top: $divider;
    padding-top: 16px;

    a {
      display: inline;
    }
  }
}

.home-route {
  h1 {
    margin-bottom: 40px;
  }

  .options section {
    h2 {
      margin-bottom: 8px;
    }

    button.primary,
    a.button.primary {
      width: auto;
    }
  }

  form.login {
    margin-top: 16px;
    > .actions > button.primary {
      margin-top: 16px;
    }
  }

  p.resend-tickets {
    margin-top: 40px;
    border-top: $divider;
    padding-top: 40px;
  }
}

details.member-menu {
  .greeting-title {
    color: $medium-gray;
  }

  .greeting-username {
    color: $dark-gray;
  }
}

div.message {
  border-radius: 4px;

  &.members-banner.logged-out {
    background-color: white;
  }
}

.mobile-content-wrapper {
  h1 {
    line-height: 36px;
  }
}

.back-button {
  top: 20px;
}

.edit-order {
  .session {
    border-radius: 4px;
    background-color: white;
  }
}

.reschedule-experience {
  .session .badge {
    width: 14px;
    height: 14px;
    font-size: 11px;
    padding-top: 1px;
  }
}

.select-available-session, .date-selector {
  .selectable-button,
  .selected-date-time {
    border-radius: 4px;
  }

  .selectable-button {
    border: 2px solid $light-gray;
    box-shadow: none;

    @include hover {
      box-shadow: none;
    }
  }
}

.guest-details-form {
  @include large {
    max-width: $identity-form-input-width;
  }
}

.section-header {
  background-color: white;
  border-radius: 4px;
}
.date-picker-wrapper .picker-day.selected,
.date-picker-wrapper .picker-day.focused {
  div {
    color: $calendar-price-text-color;
  }

  &:hover div {
    color: $calendar-price-text-color;
  }
}

.date-selector {
  ul > li {
    margin: 0;
  }
}
